import React, { useEffect, useRef } from 'react'

const ImageResizer = ({ imageUrl, maxWidth = 4096, maxHeight, onResizeComplete }) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    if (!imageUrl) return

    const img = new Image()
    img.crossOrigin = 'anonymous' // 支持跨域加载
    img.src = imageUrl

    img.onload = () => {
      // 计算图片的目标宽高
      let width = img.width
      let height = img.height

      if (maxWidth && width > maxWidth) {
        height = (height * maxWidth) / width
        width = maxWidth
      }

      if (maxHeight && height > maxHeight) {
        width = (width * maxHeight) / height
        height = maxHeight
      }

      // 使用 canvas 绘制并缩放图片
      const canvas = canvasRef.current
      if (canvas) {
        canvas.width = width
        canvas.height = height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        // 获取缩放后的图片数据 URL
        const dataUrl = canvas.toDataURL()

        // 调用回调函数返回生成的新 URL
        if (onResizeComplete) {
          onResizeComplete(dataUrl)
        }
      }
    }

    img.onerror = () => {
      console.error('图片加载失败，请检查 URL 是否正确')
    }
  }, [imageUrl, maxWidth, maxHeight, onResizeComplete])

  return <canvas ref={canvasRef} style={{ display: 'none' }} />
}

export default ImageResizer
